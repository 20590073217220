@import "~react-vis/dist/style";

/* File: font.css */
@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-Bold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./fonts/NeueMontreal-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

body {
  font-family: "'NeueMontreal','Archivo', sans-serif";
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition-duration: 300ms;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #4d4d4d;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #595959;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #696969;
}
